<template>
    <span v-if="status === 'PENDING'" class="badge bg-brown">{{status}}</span>
    <span v-if="status === 'ACTION'" class="badge bg-warning">{{status}}</span>
    <span v-if="status === 'SKIPPED'" class="badge bg-secondary">{{status}}</span>
    <span v-if="status === 'APPROVED'" class="badge bg-success">{{status}}</span>
    <span v-if="status === 'REJECTED'" class="badge bg-danger">{{status}}</span>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'WorkflowStepStatus',
    props: [ 'status'],
    setup(props) {

        return {}
    }
}
</script>

<style>

</style>